export const ENVS = {

    // CONTRACT_ADDRESS: "0x1887C45bF78562AF9e011dA2742b24154541Cf8b",
    // CHAIN_ID: "0x1",

    // Rinkeby Testnet
    CONTRACT_ADDRESS: "0x49F51A8a206cCf9A7d7E6d093f009F1835c28717",
    CHAIN_ID: "0x1",

    //Kovan Testnet
    // CONTRACT_ADDRESS : "0xBBE4CeDF2465f98097367179f52d82B6a59A8369",
    // CHAIN_ID : "42",

}